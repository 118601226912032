<template>
  <div class="flex items-center">
    <a v-if="!this.params.data.independent" :href="url" class="text-inherit hover:text-primary">{{ params.value }}</a>
    <a v-else @click="cancelSubscription(params.value)">IDP-X{{ params.value.substr(5) }}</a>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    url() {
      return `https://portal.rapid.education/a/subscriptions/${this.params.data.id}`;
    },
  },
  methods: {
    cancelSubscription(subscriptionId) {

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Cancel Subscription',
        text: `Are you sure you want to cancel this subscription`,
        accept: () => {

          this.$http.delete(`users/unknown/subscriptions/${subscriptionId}`)
            .then(response => {

              if (response.data) {

                const payload = response.data.data;

                this.$vs.notify({
                  color: 'success',
                  title: 'Subscription Cancelled',
                  text: 'Subscription successfully cancelled',
                });

              }

            })
            .catch(exception => {
              this.$vs.loading.close();

              let error = 'An unknown error occurred while cancelling this subscription';

              if (exception.response) {
                if (exception.response.data.error) {
                  error = exception.response.data.error.description;
                }
              }

              return this.$vs.notify({
                title: 'Failed to cancel subscription',
                text: error,
                color: 'danger',
              });

            });

        },
      });
    },
  },
};
</script>
